export const COOKIES_TRACKING_PREFIX = 'CMP'

export const COOKIES_TRACKING_NAME = {
  ACCEPT: 'accept',
  BANNER: 'v1',
  BACK: 'back',
  DATA_PROTECTION: 'data_protection',
  INFO: 'more_information',
  LEGAL_MENTIONS: 'legal_mentions',
  MODAL: 'v2',
  MODAL_STEP: 'step',
  REFUSE: 'refuse',
  SAVE: 'save_settings',
  SETTINGS: 'settings',
  TOGGLE: 'toggle',
}

/**
 * FIXME The legal page routes should be shared across apps.
 * They're only defined in the front-office app since they serve front-office pages.
 * However, these routes are used in the cookie modal which can be used in other apps.
 *
 * This duplication of route names is a workaround.
 */
const CMS_LEGAL_PAGE_NAME = 'legal'
export const LEGAL_PAGES = {
  COOKIES: `/${CMS_LEGAL_PAGE_NAME}/cookies`,
  DATA_PROTECTION: `/${CMS_LEGAL_PAGE_NAME}/data-protection`,
  LEGAL_MENTIONS: `/${CMS_LEGAL_PAGE_NAME}/legal-mentions`,
}
