<template>
  <div>
    <slot v-if="isVisible" />

    <RevLink class="mt-16" type="button" @click="toggleDisplay">
      <span class="body-2-bold">
        {{ i18n(label) }}
      </span>
    </RevLink>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'

import translations from './ToggleText.translations'

const i18n = useI18n()
const isVisible = ref(false)

function toggleDisplay() {
  isVisible.value = !isVisible.value
}

const label = computed(() =>
  isVisible.value ? translations.hide : translations.show,
)
</script>
