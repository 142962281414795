export default {
  fileInputErrorMaxSize: {
    id: 'buyback_conversation_max_size_error',
    defaultMessage: 'Uploaded file is too big',
  },
  fileInputErrorMimeType: {
    id: 'buyback_conversation_file_extension_error',
    defaultMessage: 'Only .png, .jpg, .jpeg, .pdf are allowed',
  },
  messageInputLabel: {
    id: 'buyback_conversation_message_bar_label',
    defaultMessage: 'Message',
  },
  sendMessage: {
    id: 'buyback_conversation_send_message',
    defaultMessage: 'Send message',
  },
  attachFile: {
    id: 'buyback_conversation_attach_file',
    defaultMessage: 'Attach file',
  },
}
