export default {
  title: {
    id: 'cookies_page_title',
    defaultMessage: 'Stating your preferences',
  },
  description: {
    id: 'cookies_settings_page_description',
    defaultMessage:
      "Back Market and its partners use cookies or similar tools (such as pixels) that help us record and access certain information on your device, such as the pages you have visited or the time at which you logged in (hereinafter referred to as cookies). Some cookies are essential for navigating our site and app. To make your experience even better, we also use cookies to measure our site and app's audience, evaluate the performance of our advertisements, and show you personalized content and advertisements. We place these cookies and share your data only with your consent. You have control and can give your consent for specific uses that we detail below.",
  },
  save: {
    id: 'cookies_page_save',
    defaultMessage: 'Save',
  },
  titleSeo: {
    id: 'cookies_page_title_seo',
    defaultMessage: 'Cookies',
  },

  toastSuccessTitle: {
    id: 'cookies_settings_toast_success_title',
    defaultMessage: 'Perfect!',
  },
  toastSuccessMessage: {
    id: 'cookies_settings_toast_success_message',
    defaultMessage: 'Your settings have been saved!',
  },
  toastErrorTitle: {
    id: 'cookies_settings_toast_error_title',
    defaultMessage: 'Oops!',
  },
  toastErrorMessage: {
    id: 'cookies_settings_toast_error_message',
    defaultMessage: 'Something went wrong',
  },
}
