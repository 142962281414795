<template>
  <div>
    <RevDivider />
    <RevContainer>
      <div class="grid grid-cols-3 py-16">
        <RevButtonBase :to="backLink">
          <IconArrowLeft size="24" />
        </RevButtonBase>
        <div class="body-1-bold justify-self-center">
          {{ recipient }}
        </div>
      </div>
    </RevContainer>
    <RevDivider />
    <RevContainer>
      <div class="hidden md:block">
        <div class="flex py-16">
          <div class="flex items-center gap-16">
            <RevIllustration
              :alt="title"
              :height="40"
              :src="image"
              style="flex-shrink: 0; height: 40px"
              :width="40"
            />
            <div class="body-2 flex flex-col">
              <RevTag
                class="self-start"
                :label="status"
                size="small"
                variant="alternate"
              />
              <span>{{ title }}</span>
              <span
                v-if="counterOfferPrice"
                class="text-static-default-low body-2-bold"
                >{{ counterOfferPrice }}
              </span>
              <span :class="priceClasses">{{ price }}</span>
            </div>
          </div>
        </div>
      </div>
    </RevContainer>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { tw } from '@backmarket/utils/string/tw'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevContainer } from '@ds/components/Container'
import { RevDivider } from '@ds/components/Divider'
import { RevIllustration } from '@ds/components/Illustration'
import { RevTag } from '@ds/components/Tag'
import { IconArrowLeft } from '@ds/icons/IconArrowLeft'

const props = defineProps<{
  image: string
  title: string
  price: string
  counterOfferPrice?: string
  status: string
  recipient: string
  backLink: string
}>()

const priceClasses = computed(() => {
  return props.counterOfferPrice
    ? tw`text-static-default-low caption-striked line-through ml-6`
    : tw`text-static-default-low`
})
</script>
