export default {
  show: {
    id: 'cookies_settings_show_more',
    defaultMessage: 'Read more',
  },
  hide: {
    id: 'cookies_settings_show_less',
    defaultMessage: 'Hide details',
  },
}
