<template>
  <RevContainer class="mx-0 my-20">
    <h1 class="heading-1 px-0 py-20 md:pt-32">
      {{ i18n(translations.title) }}
    </h1>

    <p class="body-2 mb-16">
      {{ i18n(translations.description) }}
    </p>

    <CookiesSettings
      :button-label="i18n(translations.save)"
      :shrink-button-desktop="true"
      :zone="route.name?.toString()"
      @error="openToast('error')"
      @success="openToast('success')"
    />
  </RevContainer>
</template>

<script lang="ts" setup>
import { useRoute } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import CookiesSettings from '@backmarket/nuxt-module-tracking/CookiesSettings.vue'
import { RevContainer } from '@ds/components/Container'

import { useBackmarketHead } from '~/composables/useBackmarketHead'

import translations from './Cookies.translations'

const route = useRoute()
const i18n = useI18n()
const { openErrorToast, openSuccessToast } = useTheToast()

function openToast(type: 'error' | 'success') {
  if (type === 'success') {
    openSuccessToast({
      title: i18n(translations.toastSuccessTitle),
      content: i18n(translations.toastSuccessMessage),
    })
  } else {
    openErrorToast({
      title: i18n(translations.toastErrorTitle),
      content: i18n(translations.toastErrorMessage),
    })
  }
}

useBackmarketHead({
  title: i18n(translations.titleSeo),
})
</script>
