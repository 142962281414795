<template>
  <div class="bg-static-default-low md:bg-transparent">
    <RevContainer>
      <div
        class="bg-static-default-low rounded-md flex flex-wrap items-center gap-8 px-8 py-24 md:px-16"
      >
        <div v-if="filesState.length > 0" class="flex w-full flex-wrap gap-12">
          <RevDocument
            v-for="document in filesState"
            :key="document.name"
            class="flex-1"
            deletable
            :name="document.name"
            @delete="removeAttachment(document)"
          />
        </div>
        <RevButtonRounded
          :alternative-text="i18n(translations.attachFile)"
          :disabled="isLoadingConversation || filesState.length > 4"
          :icon="IconAttachment"
          variant="secondary"
          @click="targetInputFile"
        />
        <RevInputText
          id="message"
          v-model:model-value="messageText"
          class="flex-1"
          :disabled="isLoadingConversation"
          :label="i18n(translations.messageInputLabel)"
          @keydown.enter.prevent="post"
        />
        <RevButtonRounded
          :alternative-text="i18n(translations.sendMessage)"
          :disabled="isLoadingConversation || messageText === ''"
          :icon="IconSend"
          :loading="isLoadingPostMessage"
          variant="primary"
          @click="post"
        />
      </div>
      <input
        ref="fileInput"
        aria-label="attachement-input"
        class="hidden"
        type="file"
        @change="addAttachement"
      />
    </RevContainer>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import type { BuybackMessage } from '@backmarket/http-api/src/api-specs-buyback/models'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { RevContainer } from '@ds/components/Container'
import { RevDocument } from '@ds/components/Document'
import { RevInputText } from '@ds/components/InputText'
import { IconAttachment } from '@ds/icons/IconAttachment'
import { IconSend } from '@ds/icons/IconSend'

import translations from './ConversationBar.translations'

const MAX_SIZE = 5 * 1024 * 1024
const ALLOWED_FILES = ['image/png', 'image/jpeg', 'application/pdf']

defineProps<{
  isLoadingConversation: boolean
  isLoadingPostMessage: boolean
}>()

const message = defineModel<BuybackMessage | undefined>('message', {
  required: true,
})

const { openErrorToast } = useTheToast()
const i18n = useI18n()

const messageText = ref<string>('')
const fileInput = ref<HTMLInputElement | null>(null)
const filesState = ref<File[]>([])

const post = () => {
  message.value = {
    message: messageText.value,
    files: filesState.value,
  }
  messageText.value = ''
  filesState.value = []
}

const addAttachement = ({ target }: Event) => {
  const { files } = target as HTMLInputElement
  if (!files || !files[0]) {
    return
  }
  const fileAdded = files[0]

  if (fileAdded.size > MAX_SIZE) {
    openErrorToast({ content: i18n(translations.fileInputErrorMaxSize) })

    return
  }

  if (!ALLOWED_FILES.includes(fileAdded.type)) {
    openErrorToast({ content: i18n(translations.fileInputErrorMimeType) })

    return
  }
  filesState.value.push(fileAdded)
}

const targetInputFile = () => {
  if (fileInput.value) {
    fileInput.value.click()
  }
}

const removeAttachment = (document: File) => {
  filesState.value = filesState.value.filter(
    (file) => file.name !== document.name,
  )
}
</script>
